import { ReactElement, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton } from '@material-ui/core';

const AppNotification = (props: any): ReactElement => {
    const { msg } = props
    const [notification, setNotification] = useState(true);

    const close = (): any => setNotification(false);

    return (
        <>
            {notification && msg ? <Box display='flex'
                justifyContent='space-between'
                alignItems='center'
                className="notification_bar"
            >
                <Box />
                <Box style={{ textAlign: 'center' }}>{msg}</Box>
                <IconButton onClick={close}>
                    <CloseIcon color="primary" />
                </IconButton>
            </Box> : ''}
        </>
    )
}

export default AppNotification;